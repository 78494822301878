<template>
  <div>
    <div class="pa-2 mx-auto">
      <v-card elevation="2" class="pa-2" rounded="lg">
        <v-progress-linear
          indeterminate
          :active="loadingitem"
        ></v-progress-linear>
        <div class="pa-2 mx-auto" elevation="8">
          <v-row>
            <p class="pa-2 overline">{{ thisitem.title }}</p>
            <v-spacer /><v-btn text @click="removeItem()"
              ><v-icon> mdi-trash-can </v-icon></v-btn
            >
          </v-row>

          <v-divider class="pa-2" />

          <v-row align="start">
            <v-col cols="8">
              <v-chip class="ma-1 caption" outlined>
                <v-icon left small> mdi-account-badge-outline </v-icon>
                TRST ID: {{ thisitem.id }}
              </v-chip>

              <v-chip
                v-if="thisitem.best_estimator"
                class="ma-1 caption"
                outlined
              >
                <v-icon small left> mdi-check-all </v-icon>
                Final Estimation: {{ thisitem.estimation_price/1000000 }}
                <v-icon small right>$vuetify.icons.custom</v-icon>
              </v-chip>

              <span>
                <div class="pa-2 overline">Description</div>
                <div class="px-2 caption">
                  {{ thisitem.description }}
                </div>
              </span>

              <p class="mt-1" v-if="thisitem.best_estimator === userAddress">
                <v-divider class="ma-4" />
                <v-icon left> mdi-account-check </v-icon>You are the best
                estimator.
                <span class="caption">
                  If the item is transferred, you will be rewarded
                  {{ thisitem.estimation.deposit_amount/1000000
                  }}<v-icon small right>$vuetify.icons.custom</v-icon> and additionally you will receive the incentive for this Trustless Contract .</span
                >
              </p>
              <!--  /* <p
                class="mt-1"
                v-else-if="thisitem.lowestestimator === userAddress"
                
              >*/
                <v-divider class="ma-4" />
                <v-icon left> mdi-account-arrow-left </v-icon>

                You are the lowest estimator.
                <span v-if="!thisitem.estimation_price" class="caption"
                  >If the item owner does not accept the estimation price, you
                  lose {{ thisitem.estimation.deposit_amount
                  }}<v-icon small right>$vuetify.icons.custom</v-icon> .</span
                >
              </p>
              <p
                class="mt-1"
                v-else-if="thisitem.highestestimator === userAddress"
                
              >
                <v-divider class="ma-4" />
                <v-icon left> mdi-account-arrow-right </v-icon>
                You are the highest estimator.<span
                  class="caption"
                  v-if="thisitem.transferable == false"
                >
                  If the seller does not accept the estimation price
                </span>
                <span class="caption" v-else
                  >If the seller does not ship the item, you lose
                  {{ thisitem.estimation.deposit_amount
                  }}<v-icon small right>$vuetify.icons.custom</v-icon> .</span
                >
              </p>-->
              <p class="mt-1 text-center" v-else type="caption">
                <v-divider class="ma-4" />
                You have estimated this item are not the best estimator. Tokens will be distributed
                back when the Trustless contract expires. You may
                try to withdrawl your TRST tokens in the meantime.
              </p>
            </v-col>

            <v-col cols="4">
              <div v-if="imageurl" class="d-flex flex-row-reverse text-center">
                <v-avatar class="ma-2 rounded" size="125" tile>
                  <v-img :src="imageurl"></v-img>
                </v-avatar>
              </div>
            </v-col>
          </v-row>
        </div> </v-card
      ><sign-tx
        v-if="submitted"
        :key="submitted"
        :fields="fields"
        :value="value"
        :msg="msg"
        @clicked="afterSubmit"
      ></sign-tx>
    </div>
  </div>
</template>

<script>
import { databaseRef } from "./firebase/db";
import ItemListEstimator from "./ItemListEstimator.vue";
import CreateItemPreviewAndUpload from "./CreateItemPreviewAndUpload.vue";
import SignTxEncrypted from "./SignTxEncrypted.vue";
import { Random } from "@cosmjs/crypto/";
const miscreant = require("miscreant");
import { sharedKey as x25519, generateKeyPair } from "curve25519-js";
import { toUtf8, fromBase64, toBase64 } from "@iov/encoding";


export default {
  props: ["itemid"],
  components: { ItemListEstimator },
  data() {
    return {
      loadingitem: true,

      photos: [],
      imageurl: "",

      fields: [],
      value: {},
      msg: "",
      submitted: false,
    };
  },

  mounted() {
    this.loadingitem = true;
     const imageRef = databaseRef.ref("ItemPhotoGallery/" + this.itemid + "/photos/");
    imageRef.on("value", (snapshot) => {
      const data = snapshot.val();

      if (data != null) {
        console.log(data[0]);
        this.photos = data;
        this.imageurl = data[0];
        this.loadingitem = false;
      }
    });
    this.loadingitem = false;
  },

  computed: {
    thisitem() {
      //this.loadingitem = true;
      return this.$store.getters.getItemFromListByID(this.itemid);
      this.loadingitem = false;
    },
    hasAddress() {
      return !!this.$store.state.account.address;
    },

    userAddress() {
      return this.$store.state.account.address;
    },
    valid() {
      return true;
    },
  },

  methods: {
    async afterSubmit(value) {
      this.loadingitem = true;

      this.msg = "";
      this.fields = [];
      this.value = {};
      if (value == true) {
        await this.$store.dispatch("updateItem", this.thisitem.id); //.then(result => this.newitem = result)

        await this.$store.dispatch("bankBalancesGet");
        alert("Estimation deleted");
      }
      this.submitted = false;
      this.flightre = false;
      this.loadingitem = false;
    },

    async removeItem() {
      this.loadingitem = true;
      this.flightre = true;

      const body = { delete_msg: deleteMsgMsg, itemid: this.itemid };
      this.fields = [
        ["estimator", 1, "string", "optional"],
        ["itemid", 2, "uint64", "optional"],
        ["delete_msg", 2, "bytes", "optional"],
      ];

      this.msg = "MsgDeleteEstimation";

            if (this.thisitem.transfer.location != "" || this.thisitem.transfer.shipping_region.length != 0){
        this.codeHash = this.$store.getters.getCodeHash(1)
        }else{
           this.codeHash = this.$store.getters.getCodeHash(2)
        };
      (this.value = {
        estimator: this.$store.state.account.address,
        ...body,
      }),
        (this.value.delete_msg = await this.encryptMsg(
          this.codeHash,
          this.value.delete_msg
        ));

      this.submitted = true;
    },

    async encryptMsg(contractCodeHash, msg) {
      let nonce = Random.getBytes(32);
      let random = Random.getBytes(32);

      // console.log(nonce);
      // console.log(random);
      console.log("encrypt start");

      const { privkey, pubkey } = this.GenerateNewKeyPairFromSeed(random);
      this.privkey = privkey;
      this.pubkey = pubkey;

      console.log(this.privkey);

      const txEncryptionKey = await this.getTxEncryptionKey(privkey, nonce);
      console.log("txEncryptionKey:" + txEncryptionKey);

      const siv = await miscreant.SIV.importKey(
        txEncryptionKey,
        "AES-SIV",
        cryptoProvider
      );
      console.log("contractCodeHash:" + contractCodeHash);
      console.log("JSON.stringify(msg):" + JSON.stringify(msg));

      const plaintext = toUtf8(contractCodeHash + JSON.stringify(msg));
      console.log("plaintext:" + plaintext);
      const ciphertext = await siv.seal(plaintext, [new Uint8Array()]);
      console.log("ciphertext:" + ciphertext);
      // ciphertext = nonce(32) || wallet_pubkey(32) || ciphertext
      let intArr = Uint8Array.from([...nonce, ...pubkey, ...ciphertext]);

      return toBase64(intArr);
      // console.log(this.value.init_msg);
    },

    GenerateNewSeed() {
      this.seed = secureRandom.randomUint8Array(32);
    },

    GenerateNewKeyPairFromSeed(seed) {
      const { public: pubkey, private: privkey } = generateKeyPair(seed);
      return { privkey, pubkey };
    },
  },
};
</script>
