

<template>
  <div>
    <v-app>
  <v-main :class="($vuetify.theme.dark) ? 'grey darken-4' : 'primary lighten-5'" >
        <v-container class="pb-6 pt-n2 px-0" >
          
         
             <v-row class="ma-0 pa-0 pt-n6">
          

          <v-col cols="12" sm="10" class="pa-0  mx-auto">
           
              <v-sheet min-height="90vh" class="rounded-b-xl mt-n4"  elevation="6">
                <div> 
       
                   <v-tabs
      
:fixed-tabs="$vuetify.breakpoint.smAndUp"
:center-active="$vuetify.breakpoint.xs"
      :dark="!!$vuetify.theme.dark"
      icons-and-text

  :background-color="($vuetify.theme.dark) ? 'dark' : 'light'"

  >
   <v-tab to="/account">
     Transactions<v-icon >
        mdi-cube-send
      </v-icon> 
    </v-tab> 
    <v-tab to="/account=placeditems">
     Created<v-icon >
        mdi-plus-box
      </v-icon> 
    </v-tab> 
   <!-- <v-tab to="/account=estimateditems">
      Estimated<v-icon >
        mdi-checkbox-marked
      </v-icon> 
    </v-tab>
    <v-tab to="/account=boughtitems">
      Bought<v-icon >
        mdi-shopping
      </v-icon> 
    </v-tab>
    <v-tab to="/account=likeditems">
      Liked<v-icon >
        mdi-heart
      </v-icon> 
    </v-tab>-->
  </v-tabs>
                  <!--<faucet/>-->

          <div v-if="this.$route.name == 'account'">
  <v-img src="img/design/buy.png" contain> <p class="overline font-weight-bold pt-4 text-center align-center "> Browse through history</p> <div class="text-center align-center" > <v-btn text icon @click="setTX"> <v-icon  >
        mdi-refresh
      </v-icon></v-btn></div></v-img>

            
  
                  <transactions :key="update"/>
                   </div>
                  <item-list-seller v-if="created"/>
                  
                  <item-list-estimator v-if="estimated" />
                
                  <item-list-buyer v-if="bought"/>
        
                  <item-list-interested v-if="interested"/>
                  
                </div>
                
              </v-sheet>
            </v-col> 
           </v-row><v-col cols="12" class="d-flex d-sm-none justify-center">
               
                <wallet />
              
            </v-col >
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Transactions from '../components/Transactions.vue';
import ItemListSeller from '../components/ItemListSeller.vue';
import ItemListEstimator from '../components/ItemListEstimator.vue';
import ItemListBuyer from '../components/ItemListBuyer.vue';
import ItemListInterested from '../components/ItemListInterested.vue';

export default {
  components: { Transactions, ItemListSeller, ItemListEstimator, ItemListBuyer, ItemListInterested },

  data() {
    return {
      created: false,
      estimated: false,
      bought: false,
      interested: false,
      update:true,
    };
  },

  mounted(){
    if (this.$route.params.list == "placeditems"){
this.getItemsFromSeller()

    }else if (this.$route.params.list == "estimateditems"){
this.getItemsFromEstimator()

    }else if (this.$route.params.list == "boughtitems"){
this.getItemsFromBuyer()

    }else if (this.$route.params.list == "likeditems"){
this.getInterestedItems()

    }
  },

  methods: {
    async setTX(){
            this.update = false
      await this.$store.dispatch("setTransactions", this.$store.state.account.address )
      this.update = true

    },

   getItemsFromSeller() {
      if (this.$store.state.account.address) { 
  
    //  let input = this.$store.state.account.address;
      this.$store.dispatch("setSellerItemList");
};    this.created = true
    },

     getItemsFromEstimator() {
      if (this.$store.state.account.address) {
     
    
      let input = this.$store.state.account.address;
      this.$store.dispatch("setEstimatorItemList", input);
     }   this.estimated = true
    },
     getInterestedItems() {
      if (this.$store.state.account.address) { 

     
      let input = this.$store.state.account.address;
      this.$store.dispatch("setInterestedItemList");

      } this.interested = true

    },
     getItemsFromBuyer() {
      if (this.$store.state.account.address) { 
      //const type = { type: "buyer" };
      //this.$store.dispatch("entityFetch",type);
    
      let input = this.$store.state.account.address;
      this.$store.dispatch("setBuyerItemList", input);
      }  this.bought = true

    },

},
}
</script>